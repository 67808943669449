import {Routes, Route, BrowserRouter} from 'react-router-dom';
import Header from './components/Header/Header';
import Body from './components/Body/Body';
import About from './components/About/About';
import Workout from './components/Workout/Workout';
import Allenamento from './components/Allenamento/Allenamento';
import Futuro from './components/Futuro/Futuro';
import Data from './Allenamenti.json';
function App() {

    


  return (
    <>
    <BrowserRouter>
        <Routes>
            <Route
                exact
                path="/"
                element={<Header />}
            />
            <Route
                path="/About"
                element={<About />}
            />
            <Route
                path="/Home"
                element={<Body />}
            />
             <Route
                exact
                path="/Workout"
                element={<Workout data={Data} />}
            />
             <Route
                exact
                path="/Futuro"
                element={<Futuro />}
            />
             
            <>{
            Data.map((post,idx) =>{
                return(
                    <Route 
                    exact
                    path={`/Allenamento-`+(idx+1)}
                    element={<Allenamento data={Data[idx]} />}
                />
                )
            })}
            </>
            <Route
                path="*"
                element={<Body/>}
            />

        </Routes>

    </BrowserRouter>
</>
  );
}

export default App;

/*


            -----       O         ____
            |           |        /              /\
            |           |       /              /  \
            -----       |      |              /    \
                |       |      |             /------\
                |       |       \           /        \
            -----       |        \___      /          \






*/