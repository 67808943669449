import './Allenamento.css';
import back from '../../img/back.png';
import { useNavigate } from 'react-router-dom';
function Allenamento(props){ 
  const Navigate= useNavigate();
    return(
    <>
    <img src={back} alt="" className="back" onClick={
        ()=>{
            Navigate("/Workout");
        }
    } />
    <div className="div2">
    
        <div className="div3">
        <h1>{props.data.title}</h1>
        <ul>
        
        <li><strong>Istruttore: </strong>{props.data.istruttore}</li>
        <li><strong>Frequenza: </strong>{props.data.frequenza}</li>
        <li><strong>Obbiettivo: </strong>{props.data.Obiettivo}</li>
        </ul>
        </div>
        

    </div>
     <table>
  <thead>
    <tr>
      <th>Esercizi</th>
      <th>Ripetizioni</th>
      <th>Recupero</th>
      <th>Descrizione</th>
    </tr>
  </thead>
  <tbody>
  {
           props.data.allenamenti.map(data => {
                      return(
                  <tr>
               <td><strong><a href={data.link}>{data.all}</a></strong></td>
                  <td>{data.rip}</td>
                   <td>{data.rec}</td>
                    <td>{data.desc}</td>
                     </tr>
                            )
                    })
     }
                    
    </tbody>
    </table>
     </>
    );
    
}



export default Allenamento;