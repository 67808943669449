import './Workout.css';
import back from '../../img/back.png';
import { useNavigate } from 'react-router-dom';
import work_1 from '../../img/work-1.jpg';
import work_2 from '../../img/work-2.jpg';
import work_3 from '../../img/work-3.jpg';
import work_4 from '../../img/work-4.jpg';
function Workout(props){
    const Navigate= useNavigate();
    let images =[
        {
        src:work_1
        },
        {
        src:work_1
        },
        {
        src:work_2
        },
        {
        src:work_2
        },
        {
        src:work_3
        },
        {
        src:work_3
        },
        {
        src:work_4
        },
        {
        src:work_4
        },{
            src:work_4
            },
    

];
return(

    <>
    <img src={back} alt="" className="back" onClick={
        ()=>{
            Navigate("/Home");
        }
    } />
        <div className="container-workout">
                {
                    props.data.map((post, idx) =>{
                        return(
                        
                        <div className="workout animatepop delay-1" onClick={()=>{
                            Navigate(`/Allenamento-`+(idx+1))
                        }}>
                                 <img src={images[idx].src} alt="" className="image-workout" />
                            <div  className="text-workout">
                            <h1>{post.title}</h1>
                        </div>
                    </div>);
                    })
                   
                }

        </div>
    </>

);
}

export default Workout;