import './About.css';
import back from '../../img/back.png';
import gerardo from '../../img/gerardo.jpeg';
import antonio from '../../img/antonio.jpeg';
import vincenzo from '../../img/vincenzo.jpeg';
import carmine from '../../img/carmine.jpeg';
import { useNavigate } from 'react-router-dom';
function About(){
    const Navigate= useNavigate();
return(
    <>
    <img src={back} alt="" className="back" onClick={
        ()=>{
            Navigate("/Home");
        }
    } />
   
        
    <div className="title-about animatepop">
        <h2 className='p-about'>Ciao! Ci chiamiamo <strong> Vincenzo Guarino, Carmine Sica, Antonio De Feo e Gerardo Di Iasi,</strong> e frequentiamo l’ultimo anno di istruzione superiore presso l’Istituto Tecnico Informatico di Baronissi “Margherita Hack”. <br />
Ciò che ci accomuna è l’interesse per l’informatica, nella sua totalità. Nel corso di questi cinque anni abbiamo avuto modo di abbracciare molteplici rami di questo settore, particolarmente in voga al giorno d’oggi, scoprendone uno nuovo, e agli albori, proprio quest’anno: la medicina digitale.
L’Università degli Studi di Salerno ci ha dato l’opportunità di poter sperimentare questo campo in prima persona, organizzando una competizione avente per oggetto la realizzazione di un assistente medico digitale con supporto di un sensore Howdy per il monitoraggio di parametri vitali quali frequenza cardiaca, frequenza respiratoria ed ECG.
La nostra forza di volontà ci ha spinti ad accettare l’invito propostoci, pianificando e programmando quanto sopra descritto.
Il risultato finale è frutto dell’interrelazione delle nostre diverse attitudini e passioni, che abbiamo cercato di sfruttare al meglio nell’organizzazione di questo progetto per renderle chiaramente visibili.</h2>
</div>
    <div className="container-about ">
        <div className="about animatepop delay-1">
            <img src={carmine} alt="" className="image-about"/>
            <div  className="text-about">
                            <h1>Carmine Sica</h1>
                            
                        </div>
                        <div  className="text-about2">
                            <h1>Programmer / Web Developer</h1>
                            
                        </div>
        </div>
        <div className="about animatepop delay-2">
        <img src={vincenzo} alt="" className="image-about" />
            <div  className="text-about">
                            <h1>Vincenzo Guarino</h1>
                        </div>
                        <div  className="text-about2">
                            <h1>Digital Artist / Content Creator / Video Maker</h1>
                            
                        </div>
        </div>
        <div className="about animatepop delay-3">
        <img src={antonio} alt=""className="image-about" />
            <div  className="text-about">
                            <h1>Antonio De Feo</h1>
                        </div>
                        <div  className="text-about2">
                            <h1> Researcher / Graphic Designer</h1>
                            
                        </div>
        </div>
        <div className="about animatepop delay-4">
        <img src={gerardo} alt=""className="image-about" />
            <div  className="text-about">
                            <h1>Gerardo Di Iasi</h1>
                        </div>
                        <div  className="text-about2">
                            <h1>Researcher</h1>
                            
                        </div>
        </div>
    </div>
    
    

    </>
);
}

export default About;