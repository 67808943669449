import './Header.css';
import videoIntro from '../../video/M.H.-CASSIOPEA.mp4';
import { useNavigate } from 'react-router-dom';
import {useState} from 'react';

function Header(){
  const [isActive, setIsActive] = useState(false);
  const navigate=useNavigate();
 const  HandlingEnd = () =>{
  setIsActive(current => !current);
   setTimeout(() =>{
    navigate("/Home")
   },600)

  };
    return (
      <>
        <div className="cont"
        style={{opacity: isActive ? "0":"1"}}
        >
              <div className="clip">
        <video
          src={videoIntro}
          autoPlay={true}
          muted
          onEnded={HandlingEnd}
         
        />
        </div>
      
        </div>
        <div className="screen-1" style={{display: isActive ? "none":"block"}}>
        <h1>DEVI GIRARE IL TELEFONO PER VISUALIZZARE IL VIDEO</h1>
      </div>
      </>
    );
 
}




export default Header;