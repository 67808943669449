import './Futuro.css';
import img_futuro from '../../img/Futuro.png';
import img_futuro1 from '../../img/Campo_Sportivo.png';
import img_futuro2 from '../../img/Campo_sanitario.png';
import logo from '../../img/logoscuola.png';
import { useNavigate } from 'react-router-dom';
import back from '../../img/back.png';
   
function Futuro(){
    const Navigate= useNavigate();
    return(
        <>
        <img src={back} alt="" className="back" onClick={
        ()=>{
            Navigate("/Home");
        }
    } />
    <div className='container-futuro'>
        <div className='inner-futuro'>
            <div className='futuro-image'>
            <img src={logo} style={{width:"25%"}} alt="" />
                <img className="immagine_futuro" src={img_futuro} alt="" />
            </div>
        </div>
        <div className='inner-futuro'>
            <h2><strong>Il nostro assistente medico digitale</strong> rappresenta una rivoluzione epocale, anche grazie all’utilizzo di pagine dinamiche interconnesse tramite un <strong>file json</strong> contenente tutti gli <strong>allenamenti</strong>, garantendo fruibilità e scalabilità (grazie alla semplice aggiunta/eliminazione degli allenamenti). Il risultato finale è in grado di trasformare radicalmente sia il <strong> settore sportivo che quello sanitario,</strong> garantendo la salute e il benessere dei pazienti con un livello di precisione e tempestività senza precedenti. 
            </h2>
            <br />
            <br />
            <br />
            <br />
            <h2>
            Nel <strong>contesto sportivo</strong>, la nostra soluzione potrebbe essere adottata nelle palestre e nei centri sportivi per la progettazione e l'assegnazione dei piani di allenamento, offrendo vantaggi tangibili come un'accuratezza senza pari e una velocità di risposta imbattibile. Allo stesso tempo, consentirebbe un monitoraggio continuo delle condizioni fisiche degli utenti, <strong>fornendo loro un supporto costante per ottimizzare le prestazioni</strong>.
            Per soddisfare la crescente domanda di soluzioni versatili e innovative, stiamo considerando l'integrazione della nostra applicazione con dispositivi indossabili di punta, come gli <strong>smartwatch e i braccialetti intelligenti</strong> di aziende tecnologiche. Questa collaborazione potrebbe rappresentare un'opportunità straordinaria per <strong>offrire un monitoraggio costante dei segni vitali</strong> e fornire piani di <strong>allenamento personalizzati</strong> in modo ancora più efficace e intuitivo, ponendo così le basi per un nuovo standard di cura e prestazioni nel mondo dello sport.
            </h2>
        </div>
        <div className='inner-futuro'>
            <div className='futuro-image'>
             <img className="immagine_futuro" src={img_futuro1} alt="" />
            </div>
        </div>
        <div className='inner-futuro'>
        <h2>Per quanto riguarda <strong>quello sanitario</strong>, invece, si potrebbe utilizzare l’assistente digitale per monitorare costantemente i pazienti <strong>con condizioni mediche croniche</strong>, come malattie cardiache, diabete o ipertensione, per fornire un <strong>monitoraggio continuo e intervenire tempestivamente</strong> in caso di cambiamenti nei dati vitali. Oltre ad un supporto curativo, potrebbe costituire anche un mezzo preventivo per identificare precocemente <strong>segni di potenziali problemi di salute</strong>, con la possibilità di intervenire anticipatamente per ridurre il rischio di malattie croniche o condizioni mediche. Un aspetto da non sottovalutare <strong>è la telematica</strong>, pensando di integrare l'assistente medico digitale con tecnologie di telemedicina per consentire <strong>la diagnosi e la gestione a distanza di pazienti</strong>, riducendo la necessità di visite in ospedale o in clinica, <strong>consentendo loro di rimanere indipendenti</strong> e sicuri nella propria casa, soprattutto agli anziani e alle persone con disabilità.
        </h2>
        </div>
        <div className='inner-futuro'>
            <div className='futuro-image'>
                <img className="immagine_futuro" src={img_futuro2} alt="" style={{marginBottom:"40px"}} />
            </div>
        </div>
    </div>
        </>
    );
}

export default Futuro;
